<template>
  <v-container
    style="padding: 0px"
    class="mw-1150 d-flex justify-space-between"
  >
    <Loading :loading="isLoading"></Loading>
    <v-container>
      <div class="mt-2">
        <BreadCrumbs :items="items"></BreadCrumbs>
      </div>

      <div
        :class="`${
          $vuetify.breakpoint.smAndDown ? '' : 'd-flex justify-space-between'
        } align-center  mb-7 mt-3`"
      >
        <Topic eng="Training Course" thai="หลักสูตรอบรม"></Topic>
        <div v-if="access_token">
          <v-btn @click="goto()" class="mx-3 btn-blue" x-large rounded>
            <div class="mx-4 my-3">
              <div class="font-24">ประวัติการเข้าร่วมหลักสูตร</div>
            </div>
          </v-btn>
        </div>
      </div>

      <v-row class="mt-5">
        <v-col class="mt-4" v-for="(item, i) in courses" :key="i" cols="12">
          <CourseBigPanel :isHistory="false" :model="item"></CourseBigPanel>
        </v-col>
        <v-col cols="12">
          <v-pagination
            class="my-10"
            v-model="paginate.page"
            :length="paginate.pageCount"
            circle
            @input="changePage"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Topic from "../../components/Topic.vue";
import Loading from "../../components/Loading.vue";
import BreadCrumbs from "../../components/BreadCrumbs.vue";
import CourseBigPanel from "../../components/Course/CourseBigPanel.vue";
import { mapState } from "vuex";
export default {
  components: {
    BreadCrumbs,
    Loading,
    Topic,
    CourseBigPanel,
  },
  computed: {
    ...mapState({
      courses: (state) => state.Course.courses,
      paginate: (state) => state.Course.paginate,
      access_token: (state) => state.auth.access_token,
    }),
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("Course/get", {
      row: this.row,
    });
    this.isLoading = false;
  },
  methods: {
    goto() {
      this.$router.push(`/course-history`);
    },
    async changePage(val) {
      this.isLoading = true;
      await this.$store.dispatch("Course/get", {
        page: val,
        row: this.row,
      });
      this.isLoading = false;
    },
  },
  data() {
    return {
      row: 4,
      items: [
        {
          text: "หน้าแรก",
          disabled: false,
          href: "/",
        },
        {
          text: "หลักสูตรอบรม",
          disabled: true,
        },
      ],
      isLoading: false,
    };
  },
};
</script>

<style></style>
